import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import { useQuery } from "@tanstack/react-query";
import { Respondent, Survey } from "../../types";
import useApi from "../../hooks/useApi";
import { getLatestCompletedSurvey } from "../../hooks/helpers";
import Container from "../../components/Container";
import EconomicImpact from "./EconomicImpact";

import { LockClosedIcon } from "@heroicons/react/16/solid";
import AtRiskDeepDive from "./AtRiskDeepDive";

export default function Risk() {
  
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const companyId = companyContext.companyId;

  const {
    isPending: surveysIsPending,
    error: surveysError,
    data: surveys,
  } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/company/surveys`),
    enabled: !!companyId,
  });

  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;

    const { data: latestRespondents } = useQuery<Respondent[]>({
      queryKey: ["respondents", latestCompletedSurvey?.survey_id],
      queryFn: async () => getApiData(`/company/surveys/respondents/${latestCompletedSurvey?.survey_id}`),
      enabled: !!latestCompletedSurvey,
      initialData: [],
    });
    

  return (
    <div>
      <h1 className="text-2xl font-bold">Economic Risk Impact</h1>
      {latestCompletedSurvey ? (
        <div>
        <Container title="" className="mt-3 border-2 border-red-500">
          <EconomicImpact />
        </Container>
        
        <div className="mt-12">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-3">Economic Risk Deep Dive</h1>
          </div>

          <AtRiskDeepDive respondents={latestRespondents} />
        </div>

        </div>
      ) : (
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col justify-center content-center items-center">
            <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
            <h1 className="text-xl font-bold">No Economic Risk Impact - Yet</h1>
            <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
          </div>
        </div>
      )}
    </div>
  );
}

