import { QuestionMarkCircleIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import React, { useContext, useState } from "react";
import { Participant, Respondent } from "../../types";
import { calculateEmployeeTurnover, calculateMajorCrisis, calculateSequntialTask, calculateStressCategoryCounts, calculateWorkersCompensation } from "../../hooks/helpers";
import { useQuery } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";

const EconomicRiskAnalysis = (props: { respondents: Respondent[] }) => {
  const companyContext = useContext(CompanyContext);

  const {
    isPending: participantsIsPending,
    error: participantsError,
    data: participants,
  } = useQuery<Participant[]>({
    queryKey: ["participants", companyContext.companyId],
    queryFn: async () => getApiData(`/company/users`),
    initialData: [],
  });

  const LATEST_SURVEY_NUM_EMPLOYEES = participants.length;

  const { respondents } = props;

  const { disengaged, red, yellow, highGreen, midYellow, highYellow } = calculateStressCategoryCounts(respondents);

  const employeeTurnoverCost = calculateEmployeeTurnover(red, yellow, highGreen, disengaged);
  const sequentialTaskCost = calculateSequntialTask(red, yellow, disengaged, LATEST_SURVEY_NUM_EMPLOYEES);
  const workersCompensationCost = calculateWorkersCompensation(red, yellow, disengaged);
  const majorCrisisRiskCost = calculateMajorCrisis(red, highYellow, midYellow, disengaged);

  const [expandedMetric, setExpandedMetric] = useState(null);

  const toggleMetric = (id: any) => {
    setExpandedMetric(expandedMetric === id ? null : id);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  const metrics = [
    {
      id: "turnover",
      title: "Turnover Cost",
      variables: [
        { title: "Average Salary", amount: "$35,000" },
        { title: "Replacement Cost", amount: "75% of Salary" },
        { title: "People At Risk", amount: (red + yellow + (0.2 * highGreen) + (0.3 * disengaged)).toFixed(1) },
      ],
      totalCost: employeeTurnoverCost,
      formula: "(Avg Salary × People at Risk) + Training Costs",
      example: "(35,000 × 75% × People At Risk  = 67,124",
      breakdown: [
        { label: "All Red Zone Employees", value: red, color: "text-red-500" },
        { label: "All Yellow Zone Employees", value: yellow, color: "text-yellow-500" },
        { label: "20% of High Green Employees (High Performers)", value: (0.2 * highGreen).toFixed(1), color: "text-green-500" },
        { label: "30% of Disengaged Employees", value: (0.3 * disengaged).toFixed(1), color: "text-gray-500" },
        { label: "Total at Risk of Turnover", value: (red + yellow + (0.2 * highGreen) +(0.3 * disengaged)).toFixed(1), color: "text-black" },
      ],
      researchQuote:
        `Assessment results show that ${(red + yellow + (0.2 * highGreen) +(0.3 * disengaged)).toFixed(1)} employees are at risk of turnover, spanning high-stress (red/yellow zone), disengaged, and high-performing employees. Research from the American Psychological Association (APA) and Gallup shows that stressed employees are 3.5 times more likely to quit, with burnout contributing to a 63% increase in absenteeism and a 2.6x higher likelihood of job-seeking behavior.`,
    },
    {
      id: "task_error",
      title: "Sequential Task Error",
      variables: [
        { title: "Total Assumed Revenue with 0.5% Bad Debt Applied", amount: "$9,950,000" },
        { title: "Total Employees", amount: LATEST_SURVEY_NUM_EMPLOYEES },
        { title: "Avg Rev per Employee", amount: formatter.format(9950000 / LATEST_SURVEY_NUM_EMPLOYEES) },
        { title: "People at Risk", amount: (red + 0.5 * yellow + 0.3 * disengaged).toFixed(1) },
      ],
      totalCost: sequentialTaskCost,
      breakdown: [
        { label: "All Red Zone Employees", value: red, color: "text-red-500" },
        { label: "50% of Yellow Zone Employees", value: (0.5 * yellow).toFixed(1), color: "text-yellow-500" },
        { label: "30% of Disengaged Employees", value: (0.3 * disengaged).toFixed(1), color: "text-gray-500" },
        { label: "Total at Risk of Sequential Task Error", value: (red + 0.5 * yellow + 0.3 * disengaged).toFixed(1), color: "text-black" },
      ],
      researchQuote:
        `Assessment results show that ${(red + yellow + (0.2 * highGreen) +(0.3 * disengaged)).toFixed(1)} employees are at risk of making sequential task errors due to stress. Research from the European Journal of Applied Psychology shows that when people are stressed, heart rate increases, focus wavers, and mental flexibility decreases. This makes it harder to smoothly transition from one task to the next, increasing the likelihood of mistakes or accidents.`,
    },
    {
      id: "workers_comp",
      title: "Workers Compensation Claims",
      variables: [
        { title: "Average Workers Compensation Payout", amount: "$41,757" },
        { title: "People At Risk", amount: (red + yellow + 0.3 * disengaged).toFixed(1) },
      ],
      totalCost: workersCompensationCost,
      formula: "(Avg Payout × People at Risk of Injury)",
      example: "(35,000 × 75%) + 5,000 = 67,124",
      breakdown: [
        { label: "All Red Zone Employees", value: red, color: "text-red-500" },
        { label: "All Yellow Zone Employees", value: yellow, color: "text-yellow-500" },
        { label: "30% of Disengaged Employees", value: (0.3 * disengaged).toFixed(1), color: "text-gray-500" },
        { label: "Total at Risk of Injury", value: (red + yellow + 0.3 * disengaged).toFixed(1), color: "text-black" },
      ],
      researchQuote:
        `Assessment results show that ${(red + yellow + (0.2 * highGreen) +(0.3 * disengaged)).toFixed(1)} employees are at risk of injury due to stress. Research from the National Institute of Occupational Safety and Health demonstrates that individuals experiencing higher levels of stress are significantly more likely to sustain injuries due to compromised cognitive processing, decreased coordination, increased muscle tension, and impaired decision-making capabilities, which collectively elevate the risk of accidental and performance-related injuries across multiple domains.`,
    },
    {
      id: "major_crisis",
      title: "Major Crisis",
      variables: [
        { title: "Average Settlement", amount: "$400,000" },
        { title: "People At Risk", amount: (red + 0.7 * highYellow + 0.5 * midYellow).toFixed(1) },
      ],
      totalCost: majorCrisisRiskCost,
      formula: "Average settlement * People at Risk",
      example: "(35,000 × 75%) + 5,000 = 67,124",
      breakdown: [
        { label: "All Red Zone Employees", value: red, color: "text-red-500" },
        { label: "70% of High Yellow Zone Employees", value: (0.7 * highYellow).toFixed(1), color: "text-yellow-500" },
        { label: "50% of Mid Yellow Zone Employees", value: 0.5 * midYellow, color: "text-green-500" },
        { label: "Total at Risk", value: (red + (0.7 * highYellow) + (0.5 * midYellow)).toFixed(1), color: "text-black" },
      ],
      researchQuote:
        `Assessment results indicate that ${(red + (0.7 * highYellow) + (0.5 * midYellow)).toFixed(1)} employees are at risk of contributing to a major crisis due to high stress levels. Research from the American Psychological Association (APA) has shown that chronic workplace stress increases cognitive overload, impairs risk perception, and weakens crisis response capabilities, making organizations significantly more vulnerable to large-scale disruptions`,
    },
  ];

  return (
    <div>
      {metrics.map((metric) => (
        <div key={metric.id} className="bg-white mb-4 border rounded-lg">
          {/* Header Section */}
          <div className="flex items-center justify-between bg-gray-50 p-4 cursor-pointer flex-wrap rounded-lg" onClick={() => toggleMetric(metric.id)}>
            <h2 className="text-md font-semibold text-gray-800 flex items-center">
              {metric.title}
              <QuestionMarkCircleIcon className="h-5 w-5 ml-2 cursor-pointer text-gray-500 hover:text-gray-700" title={metric.formula} />
            </h2>
            <button className="text-gray-600 hover:text-gray-800 flex items-center text-sm font-medium">
              {expandedMetric === metric.id ? "Hide Details" : "View Breakdown"}
              {expandedMetric === metric.id ? <ChevronUpIcon className="w-4 h-4 ml-1" /> : <ChevronDownIcon className="w-4 h-4 ml-1" />}
            </button>
          </div>

          {/* Summary Section */}
          <div className="flex flex-wrap sm:flex-nowrap items-center gap-4 p-4 text-center">
            <div className="flex flex-wrap sm:flex-nowrap items-center justify-center w-full">
              {metric.variables.map((v, i, arr) => (
                <div key={i} className="flex items-center w-full">
                  <div className="p-3 rounded-lg w-full text-center">
                    <span className="text-sm text-gray-500">{v.title}</span>
                    <p className="text-lg font-bold text-gray-900 mt-1">{v.amount}</p>
                  </div>
                  {i !== arr.length - 1 && <span className="font-semibold text-gray-600 text-lg">×</span>}
                </div>
              ))}
            </div>
            <span className="font-semibold text-gray-600 text-lg">=</span>
            <div className="flex flex-col items-center mx-2 w-1/4 min-w-[120px]">
              <span className="text-sm text-gray-500">Total</span>
              <p className="text-2xl font-extrabold text-red-600 mt-1">{formatter.format(metric.totalCost)}</p>
            </div>
          </div>

          {/* Expandable Breakdown */}
          {expandedMetric === metric.id && (
            <div className="border-t p-4 bg-white">
              <h3 className="text-md font-semibold text-gray-700 mb-3">Breakdown</h3>
              <p className="text-sm text-gray-600 italic mt-3">{metric.researchQuote}</p>
              <table className="w-full text-left mt-4 rounded-sm">
                <thead>
                  <tr className="bg-gray-100 text-gray-600 text-sm">
                    <th className="p-3 border-b">Assessment Results</th>
                    <th className="p-3 border-b text-center">Amount</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {metric.breakdown.map((item, index) => (
                    <tr key={index} className={index % 2 === 1 ? "bg-white" : "bg-gray-50"}>
                      <td className="p-3 border-b text-gray-600 text-sm">{item.label}</td>
                      <td className={`p-3 border-b font-bold text-center ${item.color}`}>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default EconomicRiskAnalysis;

function getApiData(arg0: string): Participant[] | PromiseLike<Participant[]> {
  throw new Error("Function not implemented.");
}
