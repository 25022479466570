import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react"

export default function GroupParticipationRates(props: {selectedParticipationCalculaiton: any}) {

    return (
        <div className="h-[85%]">
        <div className="space-y-6 mt-6 h-full overflow-scroll">
            {props.selectedParticipationCalculaiton.map((e: any, i: any) => {
                return (
                  <div key={i} className="flex justify-between gap-2 items-center">
                    <p className="font-medium text-gray-600 text-sm">{e.group}</p>
                    <p className="text-gray-900 font-semibold">{e.participationRate}%</p>
                  </div>
                )
            })}
        </div>
        </div>
    )
}