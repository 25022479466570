import { useAuth } from "../contexts/authContext";
import { useCompany } from "../contexts/companyContext";

const useApi = () => {
  const { sessionInfo, refreshAuth } = useAuth();
  const { companyId, setCompanyId } = useCompany();

  async function callAPI(
    endpoint: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    requestBody: any | null,
    retryCount: number = 0
  ): Promise<{ response: Response; body: any }> {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken.toString() ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
      },
      body: requestBody ? JSON.stringify(requestBody): undefined,
    });

    let body: any = null;
    try {
      body = await response.json();

      // retry the call after trying to get a new access token
      if (body.name === "TokenExpiredError") {
        if (!retryCount) {
          retryCount = 0;
        }
        if (retryCount <= 1) {
          retryCount++;
          await refreshAuth();
          return callAPI(endpoint, method, requestBody, retryCount);
        }
      }
    } catch (err) {}

    return { response, body };
  }

  const getApiData = async (endpoint: string) => {
    const { response, body } = await callAPI(endpoint, "GET", null);

    if (response.status === 401 && body.type === "CompanyAccessDenied") {
      setCompanyId(null);
    }

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return body ? body : null;
  };

  const postApiData = async (endpoint: string, requestBody: any): Promise<{ response: Response; body: any | null }> => {
    const { response, body } = await callAPI(endpoint, "POST", requestBody);
    return { response, body };
  };

  const putApiData = async (endpoint: string, requestBody: any): Promise<{ response: Response; body: any | null }> => {
    const { response, body } = await callAPI(endpoint, "PUT", requestBody);
    return { response, body };
  };

  const deleteApiData = async (endpoint: string): Promise<{ response: Response; body: any | null }> => {
    const { response, body } = await callAPI(endpoint, "DELETE", null);
    return { response, body };
  };

  return { getApiData, postApiData, putApiData, deleteApiData };
};

export default useApi;
